import React, { useState } from "react";
import { Fade } from "react-reveal";

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailStatus, setEmailStatus] = useState('');


  // const name = this.props.data.name;
  // const street = this.props.data.address.street;
  // const city = this.props.data.address.city;
  // // const state = this.props.data.address.state;
  // const zip = this.props.data.address.zip;
  // const phone = this.props.data.phone;
  // const message = this.props.data.contactmessage;

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      const data = new FormData();
      data.append("name", name);
      data.append("email", email);
      // data.append("phone", phone);
      data.append("subject", subject);
      data.append("message", message);
      try {
          let res = await fetch("mail.php", {
          method: "POST",
          body: data,
        });
        let resText = await res.text();
        if (res.status === 200) {
          setName("");
          setEmail("");
          // setPhone("");
          setSubject("");
          setMessage("");
          setEmailStatus(resText);
        } else {
          setEmailStatus(resText);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setEmailStatus("Proszę podać adres email.");
    }
  };
  
  return (
    <section id="contact">
      <Fade duration={1000}>
        <div className="row section-head">
          <div className="twelve columns header-col">
            <h1>
              Zapraszamy do kontaktu. 
            </h1>
          </div>
        </div>
      </Fade>

      <div className="row">
        <Fade duration={1000}>
          <div className="eight columns">
            <form onSubmit={handleSubmit}>
              <fieldset>
                <div>
                  <label htmlFor="contactName">
                    Imię i nazwisko <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={name}
                    size="35"
                    id="contactName"
                    name="contactName"
                    onChange={ e => setName(e.target.value)}q
                  />
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    E-mail <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={email}
                    size="35"
                    id="contactEmail"
                    name="contactEmail"
                    onChange={ e => setEmail(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="contactSubject">
                    Tytuł<span className="required">*</span>
                    </label>
                  <input
                    type="text"
                    value={subject}
                    size="35"
                    id="contactSubject"
                    name="contactSubject"
                    onChange={ e => setSubject(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="contactMessage">
                    Wiadomość <span className="required">*</span>
                  </label>
                  <textarea
                    cols="50"
                    rows="15"
                    value={message}
                    id="contactMessage"
                    name="contactMessage"
                    onChange={ e => setMessage(e.target.value)}
                  ></textarea>
                </div>
                {emailStatus && <div><label></label><div>{emailStatus}</div>&nbsp;</div>}
                <div>
                  <button className="submit">Wyślij</button>
                  
                </div>
              </fieldset>
            </form>
            
          </div>
        </Fade>

        <Fade duration={1000}>
          <aside className="four columns footer-widgets">
            <div className="widget widget_contact">
              
              <h4>Dane kontaktowe:</h4>
              <p>
                SERWIS AQUA S.C.
                <br/> A. ROKOWSKA, A. BIZOŃ
                <br />
                34-120 Andrychów ul. Niecała 3
                <br />
                <span>NIP 5512644140 </span>|<span> REGON 386016157 </span>< br/>
                <span>telefon: +48 518 659 974 </span>< br/>
                <span>e-mail: biuro@serwisaqua.pl</span><br/>
                <span>Nr konta bankowego:</span><br/>
                <span>21 1050 1100 1000 0092 7722 4441</span><br/>
              </p>
            </div>

            <div className="widget widget_tweets">
              <h4 className="widget-title">Nasze certyfikaty:</h4>
              <ul id="twitter">
                <li>
                  <span>
                    <img
                      src="certyfikat1.png"
                      alt="Nordic Giant Profile Pic"
                    />
                  </span>
                </li>
                <li>
                  <span>
                  <img
                      src="certyfikat2.png"
                      alt="Nordic Giant Profile Pic"
                    />
                  </span>
                </li>
              </ul>
            </div>
          </aside>
        </Fade>
      </div>
    </section>
  );
}


export default Contact;
